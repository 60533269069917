import { useState } from 'react';
import { Alert, Button, Card, CardBody, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import Footer from '../components/Footer';
import HeaderButtons from '../components/HeaderButtons';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import TTSService from '../services/TTSService';
import SelectVoice from '../components/SelectVoice';
import TranslationService from '../services/TranslationService';

function DemoScreen() {

  const Translations = TranslationService(navigator.language || navigator.userLanguage)

  const [loading, setLoading] = useState(false)

  const [error, setError] = useState('')

  const [voice, setVoice] = useState('')
  const [service, setService] = useState('')
  const [text, setText]   = useState('')
  const [url, setUrl]     = useState('')

  const allow = () => {
    return !loading && voice && text && text.length <= 500
  }

  const generate = async () => {
    setUrl('')
    setError('')
    setLoading(true)

    const body = {
      tts: {
        text: text,
        voice: voice.toLowerCase(),
        service: service
      }
    }

    await TTSService.demo(body).then((response) => {
        const blob = response.data
        const url  = URL.createObjectURL(blob)

        setUrl(url)
      })
      .catch((err) => {
        new Blob([err.response.data], {
          type: 'application/json'
        }).text().then(text => {
          setError(JSON.parse(text).error)
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Container fluid>
        <HeaderButtons />

        <Header />
                
        { error &&
          <Row className='justify-content-center' style={{
            marginBottom: '3rem'
          }}>
            <Col xxl={6}>
              <Alert style={{
                textAlign: 'center'
              }} variant='warning'>{error}</Alert>
            </Col>
          </Row>
        }
        
        <Row className='justify-content-center' style={{
          marginBottom: '1rem'
        }}>
          <Col xxl={6}>
            <SelectVoice voice={voice} setVoice={setVoice} service={service} setService={setService} />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col style={{
            marginBottom: '1rem'
          }} xxl={6}>
            <Form.Control as="textarea" rows={3} placeholder={Translations.input_placeholder} value={text} onChange={(e) => setText(e.target.value)} />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col sm={12} xxl={6}>
            <Row className='align-items-center'>
              <Col sm={12} xxl={6}>
                { url && <audio controls src={url} /> }
              </Col>
              <Col style={{
                textAlign: 'right'
              }} sm={12} xxl={6}>
                <span style={{
                  fontSize: '.8rem'
                }}>
                  {text.length}/500
                </span>
                <Button style={{
                  marginLeft: '1rem'
                }} disabled={!allow()} variant="dark" onClick={() => generate()}>
                  {Translations.input_button} { loading ? <Spinner size='sm' /> : '' }
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        
        <Row className='justify-content-center' style={{
          marginTop: '6rem',
        }}>
          <Col xxl={6}>
            <div style={{
              fontWeight: 'bolder',
              fontSize: '1.6rem'
            }}>{Translations.pricing_title}</div>
          </Col>
        </Row>
        <Row className='justify-content-center' style={{
          marginTop: '3rem'
        }}>
          <Col sm={12} xxl={6}>
            <Row>
              <Col sm={12} xxl={4}>
                <Card style={{ marginBottom: '1rem' }}>
                  <CardBody>
                    <div className='pack-title'>Starter Pack</div>
                    <div className='pack-price'>{Translations.pricing_starter_pack}</div>
                    <div>&#10003; 30,000 {Translations.pricing_credits_label}</div>
                    <div>&#10003; ~ 30 {Translations.pricing_mins_label}</div>
                    <Link to={'/register'}>
                      <Button variant='dark' className='pack-button w-100'>
                        {Translations.pricing_button_label}
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={12} xxl={4}>
                <Card style={{ marginBottom: '1rem' }}>
                  <CardBody>
                    <div className='pack-title'>Creator Pack</div>
                    <div className='pack-price'>{Translations.pricing_creator_pack}</div>
                    <div>&#10003; 100,000 {Translations.pricing_credits_label}</div>
                    <div>&#10003; ~ 2 {Translations.pricing_hours_label}</div>
                    <Link to={'/register'}>
                      <Button variant='dark' className='pack-button w-100'>
                        {Translations.pricing_button_label}
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={12} xxl={4}>
                <Card className='bg-dark'>
                  <CardBody>
                    <div className='pack-title text-light'>Pro Pack</div>
                    <div className='pack-price text-light'>{Translations.pricing_pro_pack}</div>
                    <div className='text-light'>&#10003; 500,000 {Translations.pricing_credits_label}</div>
                    <div className='text-light'>&#10003; ~ 10 {Translations.pricing_hours_label}</div>
                    <Link to={'/register'}>
                      <Button variant='light' className='pack-button w-100'>
                        {Translations.pricing_button_label}
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </Container>
    </>
  );
}

export default DemoScreen;