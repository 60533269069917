import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

import DemoSceen from './screens/DemoScreen';
import RegisterScreen from './screens/RegisterScreen';

import './index.css';
import LoginScreen from './screens/LoginScreen';
import ProtectedRoute from './ProtectedRoute';
import AppScreen from './screens/AppScreen';
import { AuthProvider } from './contexts/AuthContext';
import TermsScreen from './screens/TermsScreen';
import PrivacyScreen from './screens/PrivacyScreen';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/' element={<DemoSceen />} />
      <Route path='/register' element={<RegisterScreen />} />
      <Route path='/login' element={<LoginScreen />} />
      <Route path='/terms' element={<TermsScreen />} />
      <Route path='/privacy' element={<PrivacyScreen />} />

      <Route element={<ProtectedRoute />}>
        <Route path='/app' element={<AppScreen />} />
      </Route>
    </>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
