import { Link } from "react-router-dom";

export default {
  en: {
    tts_demo_path: '/tts_demo_en/',
    sign_in_link: 'Sign in',
    sign_up_link: 'Sign up',
    sign_out_link: 'Sign out',
    header_title: 'Easy. Fast. Cheap.',
    header_subtitle: 'Optimized for English. Better on Desktops and Tablets.',
    select_voice: 'Select voice',
    filter_name_label: 'Search by name',
    filter_name_placeholder: 'Type the voice name here',
    filter_engine: 'Engine',
    filter_gender: 'Gender',
    gender_male: 'Male',
    gender_female: 'Female',
    gender_non_binary: 'Non Binary',
    input_placeholder: 'Start typing here...',
    input_button: 'Generate speech',
    pricing_title: 'Packs built for creators and business of all sizes',
    pricing_credits_label: 'credits',
    pricing_mins_label: 'mins audio',
    pricing_hours_label: 'hours audio',
    pricing_button_label: 'Buy',
    pricing_starter_pack: '$2,99',
    pricing_starter_pack_link: 'https://buy.stripe.com/6oE16Q4GH5MF2EUbIK',
    pricing_creator_pack: '$5,99',
    pricing_creator_pack_link: 'https://buy.stripe.com/14kbLu8WX8YR6Va9AB',
    pricing_pro_pack: '$19,99',
    pricing_pro_pack_link: 'https://buy.stripe.com/5kAcPyc995MF6VaaEJ',
    privacy_link: 'Privacy',
    terms_link: 'Terms',
    register_title: 'Create an account',
    register_email_label: 'E-mail',
    register_email_placeholder: 'Enter your e-mail address',
    register_password_label: 'Password',
    register_password_placeholder: '••••••••',
    register_terms_label: 'I agree to the Terms of Service and acknowledge SaySynth\'s Privacy Policy',
    register_button_label: 'Sign up',
    register_already_registered: 'Already registered?',
    register_success: 'Nice. Your account has been created. You can sign in now.',
    register_success_link: 'Click here',
    login_title: 'Log in to your account',
    login_email_label: 'E-mail',
    login_email_placeholder: 'Enter your email address',
    login_password_label: 'Password',
    login_password_placeholder: '••••••••',
    login_error: 'No user is found with the provided credentials.',
    login_button_label: 'Sign in',
    login_dont_have_account: 'Don\'t have an account?',
    app_title: 'Text to Speech',
    app_subtitle: 'Generate realistic and captivating speech',
    app_buy_title: 'Buy more credits',
    app_history_title: 'History',
    app_history_chars: 'chars',
    app_history_load_more: 'Load more',
    app_history_all_loaded: 'This is all',
  },
  pt: {
    tts_demo_path: '/tts_demo_pt/',
    sign_in_link: 'Login',
    sign_up_link: 'Cadastro',
    sign_out_link: 'Sair',
    header_title: 'Rápido. Fácil. Barato.',
    header_subtitle: 'Otimizado para português brasileiro. Melhor em computadores e tablets.',
    select_voice: 'Selecionar voz',
    filter_name_label: 'Busca por nome',
    filter_name_placeholder: 'Digite o nome da voz aqui',
    filter_engine: 'Tecnologia',
    filter_gender: 'Gênero',
    gender_male: 'Masculino',
    gender_female: 'Feminino',
    gender_non_binary: 'Não Binário',
    input_placeholder: 'Comece escrevendo aqui...',
    input_button: 'Gerar fala',
    pricing_title: 'Pacotes pensados para criadores e empresas de todos os tamanhos',
    pricing_credits_label: 'créditos',
    pricing_mins_label: 'mins de áudio',
    pricing_hours_label: 'horas de éudio',
    pricing_button_label: 'Comprar',
    pricing_starter_pack: 'R$ 19,90',
    pricing_starter_pack_link: 'https://buy.stripe.com/bIY5n61uva2V2EU6ou',
    pricing_creator_pack: 'R$ 34,90',
    pricing_creator_pack_link: 'https://buy.stripe.com/aEU2aUb555MF6VadQX',
    pricing_pro_pack: 'R$ 89,90',
    pricing_pro_pack_link: 'https://buy.stripe.com/fZeg1K0qrcb3cfu28g',
    privacy_link: 'Política de Privacidade',
    terms_link: 'Termos de Uso',
    register_title: 'Crie uma conta',
    register_email_label: 'E-mail',
    register_email_placeholder: 'Insira seu endereço de e-mail',
    register_password_label: 'Senha',
    register_password_placeholder: '••••••••',
    register_terms_label: 'Eu confirmo que li e concordo com os Termos de Uso e a Política de Privacidade',
    register_button_label: 'Cadastre-se',
    register_already_registered: 'Já possui uma conta?',
    register_success: 'Sua conta foi criada com sucesso. Você já pode fazer login na sua conta.',
    register_success_link: 'Clique aqui',
    login_title: 'Entre na sua conta',
    login_email_label: 'E-mail',
    login_email_placeholder: 'Insira seu endereço de e-mail',
    login_password_label: 'Senha',
    login_password_placeholder: '••••••••',
    login_error: 'Nenhum usuário encontrado com os dados informados.',
    login_button_label: 'Entrar',
    login_dont_have_account: 'Ainda não tem uma conta?',
    app_title: 'Text to Speech',
    app_subtitle: 'Gere locuções realistas e cativantes',
    app_buy_title: 'Compre mais créditos',
    app_history_title: 'Histórico',
    app_history_chars: 'caracteres',
    app_history_load_more: 'Carregar mais',
    app_history_all_loaded: 'Isso é tudo',
  }
}