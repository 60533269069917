import { Col, Container, Row } from "react-bootstrap"
import Footer from "../components/Footer"
import Header from "../components/Header"

function TermsScreen() {
  return (
    <Container fluid>
      <Header />
      <Row className="justify-content-center">
        <Col xxl={6}>
          <h1>Terms of Use - SaySynth</h1>

          <p><i>Last Updated: 2024-11-11 19:28</i></p>

          <h2>1. Introduction and Acceptance of Terms</h2>

          <p>Welcome to SaySynth, a Text-to-Speech (TTS) service platform. By accessing and using SaySynth, you agree to comply with and be bound by these Terms of Use. If you do not agree with all of these terms, you should not use our services.</p>

          <h2>2. Modifications to Terms</h2>

          <p>We reserve the right to modify these Terms of Use at any time. We will notify you of substantial changes through notices on the site or other appropriate channels. Your continued use of SaySynth following any modifications constitutes acceptance of the changes.</p>

          <h2>3. Use of Service</h2>

          <p>SaySynth is intended to provide speech synthesis services for personal and professional use in accordance with the terms herein. You agree to use our services only for lawful purposes and as permitted by these Terms of Use.</p>

          <h2>4. Intellectual Property</h2>

          <p>All content, technology, software, interfaces, and trademarks associated with SaySynth are our property or licensed to us. You may not modify, copy, distribute, reproduce, or create derivative works from the content or software, except as expressly permitted by us.</p>

          <h2>5. User Conduct</h2>

          <p>You agree to:</p>
          
          <ul>
            <li>Not use SaySynth to generate or disseminate unlawful, offensive, or defamatory content.</li>
            <li>Not interfere with, compromise, or attempt to exploit vulnerabilities in our service.</li>
            <li>Not use robots, spiders, or any other automated methods to access or use our services inappropriately.</li>
          </ul>

          <h2>6. License and Usage Rights</h2>

          <p>SaySynth grants you a limited, non-exclusive, non-transferable license to use our services in accordance with these Terms. This license is revocable at any time if these terms are violated.</p>

          <h2>7. Data Privacy and Security</h2>

          <p>We take the privacy of your data seriously. Please review our Privacy Policy to understand how we collect, store, and use your personal data. By using SaySynth, you consent to the processing of your information as described in the Privacy Policy.</p>

          <h2>8. Limitation of Liability</h2>

          <p>To the maximum extent permitted by applicable law, SaySynth and its representatives are not liable for any indirect, incidental, special, or consequential damages resulting from the use or inability to use our services.</p>

          <h2>9. Account Termination</h2>

          <p>We reserve the right to suspend or terminate your account and access to SaySynth at any time if these Terms of Use are violated or if there is involvement in illegal activities.</p>

          <h2>10. Service Changes and Interruption</h2>

          <p>We may modify or discontinue SaySynth, in whole or in part, at any time, with or without notice. We are not liable for any loss resulting from changes or interruptions to our services.</p>

          <h2>11. Contact Us</h2>

          <p>If you have questions or concerns regarding these Terms of Use, please contact us via contact@saysynth.com.</p>
        </Col>
      </Row>
      <Footer />
    </Container>
  )
}

export default TermsScreen