import { useEffect, useState } from "react"
import UserService from "../services/UserService"
import moment from "moment"
import TranslationService from "../services/TranslationService"

function HistoryItem({ item }) {

  const Translations = TranslationService(navigator.language || navigator.userLanguage)

  const [url, setUrl] = useState('')

  useEffect(() => {
    getUrl()
  }, [])

  const getUrl = async () => {
    await UserService.play(item.slug)
      .then((response) => {
        const blob = response.data
        const url = URL.createObjectURL(blob)

        setUrl(url)
      })
      .catch((err) => {

      })
  }

  return (
    <div style={{
      padding: '1rem',
      fontSize: '.8rem',
      borderBottom: '1px solid #dee2e6'
    }}>
      <div style={{
        marginBottom: '.4rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }}>{item.input}</div>
      <div>
        <audio controls src={url} style={{
          width: '100%'
        }} />
      </div>
      <div style={{
        marginTop: '.4rem',
        fontSize: '.8rem',
        color: 'rgba(0, 0, 0, .4)'
      }}>
        <b style={{ textTransform: 'capitalize' }}>{item.voice}</b> - {moment(item.created_at).format('YYYY/MM/DD HH:mm')} - {item.input.length} {Translations.app_history_chars}
      </div>
    </div>
  )
}

export default HistoryItem