import { Col, Container, Form, Row, Button, Spinner } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { useEffect, useRef, useState } from "react"
import axios from "axios"
import UserService from "../services/UserService"
import SelectVoice from "../components/SelectVoice"
import TTSService from "../services/TTSService"
import HistoryItem from "../components/HistoryItem"
import { Link } from "react-router-dom"
import Pusher from "pusher-js"
import TranslationService from "../services/TranslationService"

function AppScreen() {

  const Translations = TranslationService(navigator.language || navigator.userLanguage)

  const token = localStorage.getItem('token')
  
  const { logout } = useAuth()

  const [matchMedia, setMatchMedia] = useState(window.matchMedia('(max-width: 575px)'))
  
  const [pusher, setPusher] = useState(null)

  const [loadingGenerate, setLoadingGenerate] = useState(false)
  const [loadingHistory, setLoadingHistory] = useState(false)

  const [error, setError] = useState('')

  const [history, setHistory] = useState([])
  const [page, setPage] = useState(1)
  const [all, setAll] = useState(false)

  const [user, setUser] = useState()
  
  const [text, setText] = useState('')
  const [voice, setVoice] = useState()
  const [service, setService] = useState()
  
  const [url, setUrl] = useState('')

  useEffect(() => {
    axios.defaults.headers['Authorization'] = `Bearer ${token}`

    getUser()
    getHistory(true)
  }, [token])

  useEffect(() => {
    if (url) {
      document.getElementById('tts_generated_audio').play()
    }
  }, [url])

  useEffect(() => {
    if (pusher === null && user) {
      setPusher(new Pusher('233842b23e6b6ab2c529', {
        cluster: 'us2'
      }).subscribe('notification_channel').bind(`update_${user.email}`, (data) => {
        setUser({
          ...user,
          credits: data.credits
        })
      }))
    }
  }, [pusher, user])
  
  const getUser = async () => {
    setLoadingGenerate(true)

    await UserService.user()
      .then((response) => {
        setUser(response.data)
      })
      .catch((err) => {
        
      })
      .finally(() => setLoadingGenerate(false))
  }

  const getHistory = async (reset) => {
    setLoadingHistory(true)

    const params = {
      page: reset ? 1 : page
    }

    await UserService.history(params)
      .then((response) => {
        if (response.data.length > 0 && response.data.length < 10 || response.data.length === 0) {
          setAll(true)
        } else {
          setAll(false)
        }

        if (reset) {
          setHistory(response.data)
        } else {
          setHistory(arr => [...arr.concat(response.data)])
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        if (reset) {
          setPage(2)
        } else {
          setPage(page + 1)
        }

        setLoadingHistory(false)
      })
  }

  const allow = () => {
    return !loadingGenerate && voice && text && text.length <= 1000
  }

  const generate = async () => {
    setUrl('')
    setError('')
    setLoadingGenerate(true)

    const body = {
      tts: {
        text: text,
        voice: voice.toLowerCase(),
        service: service
      }
    }

    await TTSService.create(body)
      .then((response) => {
        const blob = response.data
        const url  = URL.createObjectURL(blob)

        setUrl(url)
        
        getUser()
        getHistory(true)
      })
      .catch((err) => {
        new Blob([err.response.data], {
          type: 'application/json'
        }).text().then(text => {
          setError(JSON.parse(text).error)
        })
      })
      .finally(() => setLoadingGenerate(false))
  }
  
  return (
    <>
      { user &&
        <Container fluid style={{
          height: '100vh'
        }}>
          <Row style={{
            height: !matchMedia.matches ? '10%' : 'initial',
            borderBottom: '1px solid #dee2e6'
          }}>
            <Col className="align-content-center" sm={12} xxl={4} style={{
              borderBottom: matchMedia.matches ? '1px solid #dee2e6' : 'initial'
            }}>
              <div style={{
                marginTop: matchMedia.matches ? '1rem' : 'initial',
                fontWeight: '500',
                fontSize: '1.4rem'
              }}>{Translations.app_title}</div>
              <div style={{
                marginBottom: matchMedia.matches ? '1rem' : 'initial',
              }}>{Translations.app_subtitle}</div>
            </Col>
            <Col sm={12} xxl={8} className="align-content-center">
              <Row className="align-items-center">
                <Col className="align-content-center" style={{
                  fontSize: '.8rem',
                  borderBottom: matchMedia.matches ? '1px solid #dee2e6' : 'initial',
                  textAlign: matchMedia.matches ? 'right' : 'initial'
                }} xs={12} xxl={3}>
                  <div style={{
                    marginTop: matchMedia.matches ? '1rem' : 'initial',
                    marginBottom: matchMedia.matches ? '1rem' : 'initial',
                  }}>
                    {Translations.app_buy_title}
                    <br/>
                    <small>{user.email}</small> | <small><Link className="link-dark" onClick={() => logout()}>{Translations.sign_out_link}</Link></small>
                  </div>
                </Col>
                <Col className="text-end" xs={4} xxl={3}>
                  <a className="link-dark d-inline-block" target="_blank" href={`${Translations.pricing_starter_pack_link}?prefilled_email=${user.email}`} title="Starter Pack" style={{
                    marginTop: matchMedia.matches ? '1rem' : 'initial',
                    marginBottom: matchMedia.matches ? '1rem' : 'initial',
                  }}>
                    <div style={{ fontSize: '.8rem' }}><b>Starter Pack ({Translations.pricing_starter_pack})</b></div>
                    <div style={{ fontSize: '.6rem' }}>30,000 {Translations.pricing_credits_label} (~ 30 {Translations.pricing_mins_label})</div>
                  </a>
                </Col>
                <Col className="text-end" xs={4} xxl={3}>
                  <a className="link-dark d-inline-block" target="_blank" href={`${Translations.pricing_creator_pack_link}?prefilled_email=${user.email}`} title="Creator Pack" style={{
                    marginTop: matchMedia.matches ? '1rem' : 'initial',
                    marginBottom: matchMedia.matches ? '1rem' : 'initial',
                  }}>
                    <div style={{ fontSize: '.8rem' }}><b>Creator Pack ({Translations.pricing_creator_pack})</b></div>
                    <div style={{ fontSize: '.6rem' }}>100,000 {Translations.pricing_credits_label} (~ 2 {Translations.pricing_hours_label})</div>
                  </a>
                </Col>
                <Col className="text-end" xs={4} xxl={3}>
                  <a className="link-dark d-inline-block" target="_blank" href={`${Translations.pricing_pro_pack_link}?prefilled_email=${user.email}`} title="Pro Pack" style={{
                    marginTop: matchMedia.matches ? '1rem' : 'initial',
                    marginBottom: matchMedia.matches ? '1rem' : 'initial',
                  }}>
                    <div style={{ fontSize: '.8rem' }}><b>Pro Pack ({Translations.pricing_pro_pack})</b></div>
                    <div style={{ fontSize: '.6rem' }}>500,000 {Translations.pricing_credits_label} (~ 10 {Translations.pricing_hours_label})</div>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ height: !matchMedia.matches ? '90%' : 'initial' }}>
            <Col xxl={9}>
              <Row className="align-content-end" style={{
                height: !matchMedia.matches ? '10%' : 'initial',
                marginTop: matchMedia.matches ? '1rem' : 'initial',
              }}>
                <Col style={{
                  marginBottom: matchMedia ? '.4rem' : 'initial'
                }}>
                  <SelectVoice voice={voice} setVoice={setVoice} service={service} setService={setService} />
                </Col>
              </Row>
              <Row style={{
                height: !matchMedia.matches ? '80%' : 'initial'
              }}>
                <Col>
                  <Form.Control rows={matchMedia.matches ? 8 : 0} as="textarea" style={{
                    resize: 'none',
                    height: '100%',
                    border: 'none',
                    padding: 0,
                    boxShadow: 'none'
                  }} placeholder={Translations.input_placeholder} value={text} onChange={(e) => setText(e.target.value)} />
                </Col>
              </Row>
              <Row className="align-content-center" style={{
                height: !matchMedia.matches ? '10%' : 'initial', borderTop: '1px solid #dee2e6',
              }}>
                <Col className="align-content-center" sm={12} xxl={6}>
                  <div style={{
                    marginTop: matchMedia.matches ? '1rem' : 'initial',
                    marginBottom: matchMedia.matches ? '1rem' : 'initial',
                  }}>
                    { url && <audio id="tts_generated_audio" controls src={url} style={{ width: '100%' }} /> }
                    { error && <div className="text-danger">{error}</div> }
                  </div>
                </Col>
                <Col className="align-content-center" style={{ textAlign: 'right' }} sm={12} xxl={6}>
                  <span style={{
                    fontSize: '.8rem'
                  }}>
                    {text.length.toLocaleString('en-US')}/{user.credits.toLocaleString('en-US')}
                  </span>
                  <Button style={{
                    marginLeft: '1rem'
                  }} disabled={!allow()} variant="dark" onClick={() => generate()}>
                    {Translations.input_button} { loadingGenerate ? <Spinner size='sm' /> : '' }
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xxl={3} style={{
              borderLeft: '1px solid #dee2e6',
              height: !matchMedia.matches ? '100%' : 'initial'
            }}>
              <Row className="align-content-center" style={{
                  height: !matchMedia.matches ? '5%' : 'initial',
                  borderBottom: '1px solid #dee2e6'
                }}>
                <Col>
                  <div style={{
                    marginTop: matchMedia.matches ? '1rem' : 'initial',
                    marginBottom: matchMedia.matches ? '1rem' : 'initial',
                    fontWeight: '500',
                    fontSize: '1rem'
                  }}>{Translations.app_history_title}</div>
                </Col>
              </Row>
              <Row style={{
                height: !matchMedia.matches ? '95%' : 'initial',
                overflowY: 'scroll'
              }}>
                <Col>
                  <Row>
                    <Col style={{ padding: 0 }}>
                      { history.map(item => <HistoryItem key={item.slug} item={item} />) }
                    </Col>
                  </Row>
                  { loadingHistory && <Row>
                    <Col className="text-center" style={{ padding: '1rem 0 0' }}>
                      <Spinner size="sm" />
                    </Col>
                  </Row> }
                  <Row>
                    <Col style={{ padding: '1rem' }}>
                      { !all &&
                        <Button className="w-100" style={{ borderRadius: 9999 }} variant="dark" size="sm" onClick={() => {
                          getHistory(false)
                        }}>{Translations.app_history_load_more}</Button>
                      }
                      { all &&
                        <div className="text-center">{Translations.app_history_all_loaded}</div>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      }
    </>
  )
}

export default AppScreen