import HeaderButtons from "../components/HeaderButtons"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AuthService from "../services/AuthService";
import { useAuth } from "../contexts/AuthContext";
import TranslationService from "../services/TranslationService";

function LoginScreen() {

  const Translations = TranslationService(navigator.language || navigator.userLanguage)

  const { isAuthenticated, login } = useAuth()
  
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const [error, setError] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/app')
    }
  }, [isAuthenticated])

  const allow = () => {
    return !loading && email && password
  }

  const create = async () => {
    setLoading(true)
    setError(false)

    const body = {
      auth: {
        email, password
      }
    }

    await AuthService.signin(body)
      .then((response) => {
        login(response.data.auth.token)
      })
      .catch((err) => {
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Container fluid>
      <HeaderButtons />

      <Header />

      <Row className='justify-content-center' style={{
        marginBottom: '2rem'
      }}>
        <Col xxl={6}>
          <div style={{
            fontWeight: 'bolder',
            fontSize: '1.6rem'
          }}>{Translations.login_title}</div>
        </Col>
      </Row>

      <Row className='justify-content-center'>
        <Col xxl={6}>
          <Row>
            <Col xxl={6}>
              <Form.Label>{Translations.login_email_label}</Form.Label>
              <Form.Control type="email" placeholder={Translations.login_email_placeholder} value={email} onChange={(e) => setEmail(e.target.value)} />
              <Form.Label style={{ marginTop: '1rem' }}>{Translations.login_password_label}</Form.Label>
              <Form.Control type="password" placeholder={Translations.login_password_placeholder} value={password} onChange={(e) => setPassword(e.target.value)} />
              { error &&
                <Form.Control.Feedback className="d-block" type="invalid">{Translations.login_error}</Form.Control.Feedback>
              }
              <Button disabled={!allow()} variant="dark" className="w-100" style={{ marginTop: '1rem', marginBottom: '2rem' }} onClick={() => create()}>
                { loading ? <Spinner size="sm" /> : Translations.login_button_label }
              </Button>
              <div className="text-center">
              {Translations.login_dont_have_account} <Link className="link-dark" to={'/register'}>{Translations.sign_up_link}</Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer />
    </Container>
  )
}

export default LoginScreen