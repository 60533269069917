import HeaderButtons from "../components/HeaderButtons"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useState } from "react";
import UserService from "../services/UserService";
import TranslationService from "../services/TranslationService";

function RegisterScreen() {

  const Translations = TranslationService(navigator.language || navigator.userLanguage)

  const [loading, setLoading] = useState(false)

  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [terms, setTerms] = useState(false)

  const allow = () => {
    return !loading && email && password && terms
  }

  const create = async () => {
    setLoading(true)
    setErrors()

    const body = {
      user: {
        email, password
      }
    }

    await UserService.create(body)
      .then((response) => {
        setSuccess(true)
        setEmail('')
        setPassword('')
        setTerms(false)
      })
      .catch((err) => {
        setErrors(err.response.data.errors)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Container fluid>
      <HeaderButtons />

      <Header />

      { success &&
        <Row className='justify-content-center' style={{
          marginBottom: '3rem'
        }}>
          <Col xxl={6}>
            <Alert style={{
              textAlign: 'center'
            }} variant='success'>{Translations.register_success} <Link to={'/login'}>{Translations.register_success_link}</Link>.</Alert>
          </Col>
        </Row>
      }

      <Row className='justify-content-center' style={{
        marginBottom: '2rem'
      }}>
        <Col xxl={6}>
          <div style={{
            fontWeight: 'bolder',
            fontSize: '1.6rem'
          }}>{Translations.register_title}</div>
        </Col>
      </Row>

      <Row className='justify-content-center'>
        <Col xxl={6}>
          <Row>
            <Col xxl={6}>
              <Form.Label>{Translations.register_email_label}</Form.Label>
              <Form.Control type="email" placeholder={Translations.register_email_placeholder} value={email} onChange={(e) => setEmail(e.target.value)} />
              { errors && errors.email && errors.email.map(error => (
                <Form.Control.Feedback className="d-block" type="invalid">{error}</Form.Control.Feedback>
              )) }
              <Form.Label style={{ marginTop: '1rem' }}>{Translations.register_password_label}</Form.Label>
              <Form.Control type="password" placeholder={Translations.register_password_placeholder} value={password} onChange={(e) => setPassword(e.target.value)} />
              { errors && errors.password && errors.password.map(error => (
                <Form.Control.Feedback className="d-block" type="invalid">{error}</Form.Control.Feedback>
              )) }
              <Form.Check style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <Form.Check.Input checked={terms} onChange={() => setTerms(!terms)} />
                <Form.Check.Label style={{
                  fontSize: '.8rem'
                }}>{Translations.register_terms_label}</Form.Check.Label>
              </Form.Check>
              <Button disabled={!allow()} variant="dark" className="w-100" style={{ marginBottom: '2rem' }} onClick={() => create()}>
                { loading ? <Spinner size="sm" /> : Translations.register_button_label }
              </Button>
              <div className="text-center">
                {Translations.register_already_registered} <Link className="link-dark" to={'/login'}>{Translations.sign_in_link}</Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer />
    </Container>
  )
}

export default RegisterScreen