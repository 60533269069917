import { Badge, Col, Dropdown, Form, FormCheck, Row } from 'react-bootstrap';
import VoiceService from '../services/VoiceService';
import { useEffect, useState } from 'react';
import TranslationService from '../services/TranslationService';

function SelectVoice({ voice, setVoice, service, setService }) {

  const Translations = TranslationService(navigator.language || navigator.userLanguage)

  const [voices, setVoices] = useState([])
  const [filteredVoices, setFilteredVoices] = useState([])
  const [filters, setFilters] = useState({
    engines: ["voicefy", "openai", "amazonpolly"],
    genders: ["Male", "Female", "Non Binary"],
    name: ''
  })

  useEffect(() => {
    if (voices.length === 0) {
      getVoices()
    }
  }, [voices])

  useEffect(() => {
    if (voices.length > 0) {
      setFilteredVoices(voices.filter(voice => filters.engines.includes(voice.service) && filters.genders.includes(voice.gender) && voice.name.toLowerCase().includes(filters.name.toLowerCase())))
    }
  }, [filters, voices])

  const getVoices = async () => {
    await VoiceService.list()
      .then((response) => {
        setVoices(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const play = (event, name, service) => {
    event.stopPropagation()
    event.preventDefault()

    document.getElementById('tts_demo_play').setAttribute('src', `${Translations.tts_demo_path}tts_demo_${name.toLowerCase()}_${service}.mp3`)
    document.getElementById('tts_demo_play').play()
  }

  const getServiceName = (service) => {
    switch (service) {
      case "voicefy":
        return "ElevenLabs"
      case "openai":
        return "OpenAI"
      case "amazonpolly":
        return "Amazon Polly"
      default:
        break;
    }
  }

  const checkEngine = (service) => {
    if (filters.engines.includes(service)) {
      setFilters({
        ...filters,
        engines: filters.engines.filter(item => item != service)
      });
    } else {
      setFilters({
        ...filters,
        engines: [...filters.engines, service]
      })
    }
  }
  
  const checkGender = (gender) => {
    if (filters.genders.includes(gender)) {
      setFilters({
        ...filters,
        genders: filters.genders.filter(item => item != gender)
      });
    } else {
      setFilters({
        ...filters,
        genders: [...filters.genders, gender]
      })
    }
  }

  return (
    <>
      <audio id="tts_demo_play" />

      <Dropdown>
        <Dropdown.Toggle variant='dark'>
          { voice ? voice : Translations.select_voice }
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: '24rem', overflowY: 'scroll' }}>
          <Dropdown.Header>
            <Row>
              <Col>
                <Form.Label><b>{Translations.filter_name_label}</b></Form.Label>
                <Form.Control size='sm'style={{ marginBottom: '1rem' }} placeholder={Translations.filter_name_placeholder} value={filters.name} onChange={(e) => {
                  setFilters({
                    ...filters,
                    name: e.target.value
                  })
                }} />
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ marginBottom: '.5rem' }}>
                  <b>{Translations.filter_engine}</b>
                </div>
                <FormCheck>
                  <FormCheck.Input checked={filters.engines.includes("voicefy")} onChange={() => checkEngine("voicefy")} />
                  <FormCheck.Label>ElevenLabs</FormCheck.Label>
                </FormCheck>
                <FormCheck>
                  <FormCheck.Input checked={filters.engines.includes("openai")} onChange={() => checkEngine("openai")} />
                  <FormCheck.Label>OpenAI</FormCheck.Label>
                </FormCheck>
                <FormCheck>
                  <FormCheck.Input checked={filters.engines.includes("amazonpolly")} onChange={() => checkEngine("amazonpolly")} />
                  <FormCheck.Label>Amazon Polly</FormCheck.Label>
                </FormCheck>
              </Col>
              <Col>
                <div style={{ marginBottom: '.5rem' }}>
                  <b>{Translations.filter_gender}</b>
                </div>
                <FormCheck>
                  <FormCheck.Input checked={filters.genders.includes("Male")} onChange={() => checkGender("Male")} />
                  <FormCheck.Label>{Translations.gender_male}</FormCheck.Label>
                </FormCheck>
                <FormCheck>
                  <FormCheck.Input checked={filters.genders.includes("Female")} onChange={() => checkGender("Female")} />
                  <FormCheck.Label>{Translations.gender_female}</FormCheck.Label>
                </FormCheck>
                <FormCheck>
                  <FormCheck.Input checked={filters.genders.includes("Non Binary")} onChange={() => checkGender("Non Binary")} />
                  <FormCheck.Label>{Translations.gender_non_binary}</FormCheck.Label>
                </FormCheck>
              </Col>
            </Row>
          </Dropdown.Header>
          { filteredVoices.map((voice) => (
            <Dropdown.Item key={`${voice.name}_${voice.service}`} style={{ minWidth: '25rem', borderBottom: '1px solid #dee2e6' }} onClick={() => {
              setVoice(voice.name)
              setService(voice.service)
            }}>
              <Row className='align-items-center' style={{ padding: '.5rem 0' }}>
                <Col xs={1}>
                  <div className='voice-play' onClick={(e) => play(e, voice.name, voice.service)}>&#9658;</div>
                </Col>
                <Col xs={10} style={{ pointerEvents: 'none' }}>
                  <Row>
                    <Col style={{ marginLeft: '.5rem' }}>
                      <span style={{ fontWeight: '500' }}><small>{voice.name}</small></span> <small>({voice.gender})</small>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ marginLeft: '.5rem' }}>
                      <Badge pill bg='secondary' text='light' style={{ fontWeight: 'normal' }}>
                        <small>{getServiceName(voice.service)}</small>
                      </Badge>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Dropdown.Item>  
          )) }
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default SelectVoice