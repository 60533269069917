import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TranslationService from '../services/TranslationService';

function Footer () {

  const Translations = TranslationService(navigator.language || navigator.userLanguage)

  return (
    <Row className='justify-content-center' style={{
      marginTop: '6rem',
      marginBottom: '3rem'
    }}>
      <Col xxl={6} style={{
        fontSize: '.8rem'
      }}>
        © 2024 SaySynth
        <Link className='link-dark' to={'/privacy'} style={{marginLeft: '.4rem'}}>{Translations.privacy_link}</Link>
        <Link className='link-dark' to={'/terms'} style={{marginLeft: '.4rem'}}>{Translations.terms_link}</Link>
      </Col>
    </Row>
  )
}

export default Footer;