import { Col, Row } from 'react-bootstrap';
import TranslationService from '../services/TranslationService';

function Header() {

  const Translations = TranslationService(navigator.language || navigator.userLanguage)

  return (
    <>
      <Row className='justify-content-center'>
        <Col xxl={6}>
          <img className='logo' src='/logo.webp' />
        </Col>
      </Row>
      <Row className='justify-content-center'>
        <Col xxl={6}>
          <div style={{
            paddingTop: '3rem',
            textAlign: 'center',
            fontWeight: 'bolder',
            fontSize: '3rem'
          }}>
            {Translations.header_title}
          </div>
          <div style={{
            textAlign: 'center',
            marginBottom: '3rem'
          }}>
            {Translations.header_subtitle}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Header;