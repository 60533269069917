import axios from "axios"
import Environment from "../constants/Environment"

export default {

  demo: (body) => {
    return axios.post(`${Environment.api_host}/tts/demo`, body, {
      responseType: 'blob'
    })
  },

  create: (body) => {
    return axios.post(`${Environment.api_host}/tts/create`, body, {
      responseType: 'blob'
    })
  }

}