import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TranslationService from '../services/TranslationService';


function HeaderButtons() {
  
  const Translations = TranslationService(navigator.language || navigator.userLanguage)

  return (
    <Row className='justify-content-center' style={{
      marginTop: '1rem'
    }}>
      <Col xxl={6} style={{
        textAlign: 'right',
        fontSize: '.8rem'
      }}>
        <Link to={'/login'}><Button variant='dark' size='sm'>{Translations.sign_in_link}</Button></Link>
        <Link className='link-dark' to={'/register'} style={{ marginLeft: '.4rem' }}>{Translations.sign_up_link}</Link>
      </Col>
    </Row>
  )
}

export default HeaderButtons