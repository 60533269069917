import { Col, Container, Row } from "react-bootstrap"
import Footer from "../components/Footer"
import Header from "../components/Header"

function PrivacyScreen() {
  return (
    <Container fluid>
      <Header />
      <Row className="justify-content-center">
        <Col xxl={6}>
          <h1>Privacy Policy - SaySynth</h1>

          <p><i>Last Updated: 2024-11-11 20:07</i></p>

          <h2>1. Introduction</h2>

          <p>SaySynth is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our Text-to-Speech (TTS) services. By accessing and using SaySynth, you agree to the practices described in this policy.</p>

          <h2>2. Information Collected</h2>

          <p>We may collect various types of information about you, including:</p>

          <ul>
            <li>Account Information: Such as name, email address, and password, provided when you register.</li>
            <li>Usage Information: Data on your activity on SaySynth, including usage frequency, preferences, and interactions with our service.</li>
            <li>Technical Data: Includes IP address, browser type, operating system, and other device-related information.</li>
            <li>Submitted Content: Text files or other data you provide for text-to-speech conversion.</li>
          </ul>

          <h2>3. How We Use Your Information</h2>

          <p>We use the information collected to provide, improve, and personalize our services, including:</p>
          
          <ul>
            <li>Managing and enhancing the user experience.</li>
            <li>Analyzing service usage and conducting internal research.</li>
            <li>Sending service-related communications, such as updates and important notifications.</li>
            <li>Ensuring the platform’s security and integrity.</li>
          </ul>

          <h2>4. Sharing Information with Third Parties</h2>

          <p>SaySynth does not sell your personal information to third parties. We may share information with trusted third parties, including:</p>
          
          <ul>
            <li>Service Providers and Partners: Who help us deliver and improve our services, such as infrastructure providers and data analytics providers.</li>
            <li>Legal Authorities: When required by law, or to protect our rights, users, or the general public.</li>
          </ul>

          <h2>5. Data Security</h2>

          <p>We implement appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no data transmission over the Internet is 100% secure, and we cannot guarantee absolute security.</p>

          <h2>6. Your Privacy Rights</h2>

          <p>Depending on your jurisdiction, you may have the right to:</p>

          <ul>
            <li>Access, correct, or delete your personal information.</li>
            <li>Restrict or object to the processing of your data.</li>
            <li>Request data portability, where applicable.</li>
          </ul>

          <p>To exercise these rights, please contact us at the address provided below.</p>

          <h2>7. Cookies and Similar Technologies</h2>

          <p>We use cookies and similar technologies to enhance user experience, personalize content, and analyze usage. You can adjust cookie settings through your browser, but some functionalities of SaySynth may be affected.</p>

          <h2>8. Data Retention</h2>

          <p>We retain your personal information only as long as necessary to fulfill the purposes described in this policy unless a longer retention period is required or permitted by law.</p>

          <h2>9. Updates to the Privacy Policy</h2>

          <p>We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We will notify you of significant changes through the site or other appropriate means.</p>

          <h2>10. Contact Us</h2>

          <p>If you have any questions or concerns about this Privacy Policy, please contact us at contact@saysynth.com.</p>
        </Col>
      </Row>
      <Footer />
    </Container>
  )
}

export default PrivacyScreen