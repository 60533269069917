import axios from "axios"
import Environment from "../constants/Environment"

export default {

  create: (body) => {
    return axios.post(`${Environment.api_host}/users`, body)
  },

  user: () => {
    return axios.post(`${Environment.api_host}/users/me`)
  },

  history: (params) => {
    return axios.post(`${Environment.api_host}/users/history`, null, { params })
  },

  play: (slug) => {
    return axios.post(`${Environment.api_host}/users/play/${slug}`, null, {
      responseType: 'blob'
    })
  }

}